<template>
	<div class="branch">
		<div class="my-title">
			<div>我的网点</div>
		</div>
		<div v-if="returnExtendData && returnExtendData != '4'">
			<el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm" style="width: 800px"
				label-position="rigth" label-width="220px">
				<el-form-item label="网点名称:" prop="cooperationNetworkName">
					<p>{{ ruleForm.cooperationNetworkName }}</p>
				</el-form-item>
				<el-form-item label="网点区域:">
					<p>{{ ruleForm.networkAreaName.toString() }}</p>
				</el-form-item>

				<el-form-item label="网点简称:" prop="networkSimpleName">
					<p>{{ ruleForm.networkSimpleName }}</p>
				</el-form-item>

				<el-form-item label="所属机构:" prop="financialOrganizationsName">
					<p>{{ ruleForm.financialOrganizationsName }}</p>
				</el-form-item>
				<el-form-item label="网点级别:" prop="networkLevel">
					<p v-if="ruleForm.networkLevel">
						{{ NetworkLevel[ruleForm.networkLevel - 1].label }}
					</p>
				</el-form-item>

				<el-form-item label="网点负责人:">
					<p>{{ ruleForm.networkPerson }}</p>
				</el-form-item>

				<el-form-item label="悦融易对接人:">
					<p>{{ ruleForm.networkManagerName }}</p>
				</el-form-item>
				<el-form-item label="对接人电话:">
					<p>{{ networkManagerTele }}</p>
				</el-form-item>
				<el-form-item label="政策优势:">
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 14 }" placeholder="请输入政策优势"
						v-model="ruleForm.advantagePolicy"></el-input>
				</el-form-item>

				<el-form-item label="核心竞争力补充:">
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 14 }" placeholder="请输入内容"
						v-model="ruleForm.coreCompetitivenessSupplement">
					</el-input>
				</el-form-item>
			</el-form>
			<div style="
					display: flex;
					justify-content: space-around;
					padding: 100px 0;
				">
				<el-button type="primary" @click="changeUpdateCooperationNetwork">保存我的网点信息</el-button>
			</div>
		</div>

		<div style="padding-top: 65px" v-else>
			<el-empty description="你的信息正在审核中，审核完毕后会显示网点信息，请稍等..."></el-empty>
		</div>
		<!-- <branchDialog :dialogVisible="true" /> -->
	</div>
</template>

<script>
import {
	itemByType,
	networkAreaList,
	readCooperationNetwork,
	updateCooperationNetwork,
} from '@/api/index.js';
import branchDialog from './module/branchDialog.vue';
export default {
	name: 'branch',
	data() {
		return {
			dialogVisible: false,
			ruleForm: {
				cooperationNetworkName: '',
				networkAreaId: '',
				networkAreaName: '',
				networkSimpleName: '',
				financialOrganizationsId: '',
				financialOrganizationsName: '',
				networkLevel: '',
				networkPerson: '',
				networkManagerId: '',
				networkManagerName: '',
				advantagePolicy: '',
				coreCompetitivenessSupplement: '',
			},
			returnExtendData: '',
			networkManagerTele: '',
			NetworkLevel: [],
			options: [],
			useless: [],
		};
	},
	components: { branchDialog },
	methods: {
		async getGradeList(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			if (res.returncode === 0) {
				this[name] = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
		async getNetworkAreaList() {
			const res = await networkAreaList();
			this.options = res;
		},
		async changeUpdateCooperationNetwork() {
			const res = await updateCooperationNetwork({
				advantagePolicy: this.ruleForm.advantagePolicy,
				coreCompetitivenessSupplement:
					this.ruleForm.coreCompetitivenessSupplement,
				cooperationNetworkId:
					this.$store.state.userInfo.cooperationNetworkId,
			});
			if (res.returncode == 0) {
				this.$message({
					message: '保存成功',
					type: 'success',
				});
			}
		},
		async getReadCooperationNetwork() {
			const res = await readCooperationNetwork({
				cooperationNetworkId:
					this.$store.state.userInfo.cooperationNetworkId,
			});
			res.cooperationNetworkVO.networkLevel = res.cooperationNetworkVO
				.networkLevel
				? res.cooperationNetworkVO.networkLevel + ''
				: '';
			res.cooperationNetworkVO.networkAreaName = res.cooperationNetworkVO
				.networkAreaName
				? res.cooperationNetworkVO.networkAreaName.split(',')
				: [];

			this.ruleForm = res.cooperationNetworkVO;
			this.returnExtendData = res.presidentStatus;
			this.networkManagerTele = res.networkManagerTele;
		},
		changeNetworkAreaName() {
			const checkedNodes = this.$refs['cascaderUnit'].getCheckedNodes(); //在data里面找，如下图
			const networkAreaIdLIst = [];
			const networkAreaNameList = [];
			checkedNodes.forEach((item) => {
				networkAreaIdLIst.push(item.value);
				networkAreaNameList.push(item.label);
			});
			this.ruleForm.networkAreaList = networkAreaIdLIst.join();
			this.ruleForm.networkAreaName = networkAreaNameList.join();
		},
		selectFinancialOrganizetionList(data) {
			this.ruleForm.financialOrganizationsId =
				data[0].financialOrganizationsId;
			this.ruleForm.financialOrganizationsName = data[0].fullName;
		},
	},
	created() {
		// 网点级别接口（
		this.getGradeList('NetworkLevel');
		// // 网点区域接口
		// this.getNetworkAreaList();
		// 获取
		this.getReadCooperationNetwork();
	},
};
</script>
<style lang="scss" scoped>
.branch {
	width: 100%;
	height: 100%;
}

p {
	color: rgb(96, 98, 102);
	margin-left: 10px;
}
</style>
